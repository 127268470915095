import{createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import masterService  from '../Services/masterService'

const initialState ={
    masterDataList:[{"stars":[],"states":[]}],
    isMasterError:false,
    isMasterSuccess:false,
    isMasterLoading:false,
    isMastermessage:'',

    masterDataReligionList:[],
    isMasterReligionError:false,
    isMasterReligionSuccess:false,
    isMasterReligionLoading:false,

    isQualificationListError:false,
    isQualificationSuccess:false,
    isQualificationLoading:false,
    qualifications:[],

    isCreateQualificationLoading:false,
    isCreateQualificationSuccess:false,
    isCreateQualificationError:false,
    isCreateQualificationMessage:'',

    isQualificationByIdloading:false,
    isQualificationByIdSuccess:false,
    isQualificationByIdError:false,
    isQualificationByIdMessage:false,
    qualification:{},

    isUpdateQualificationLoading:false,
    isUpdateQualificationSuccess:false,
    isUpdateQualificationError:false,
    isUpdateQualificationMessage:'',

    isJobListLoading:false,
    isJobListSuccess:false,
    isJobListError:false,
    jobs:[],

    isCreateJobLoading:false,
    isCreateJobSuccess:false,
    isCreateJobSuccess:false,
    CreateJobMessage:'',

    isDistrictListError:false,
    isDistrictListSuccess:false,
    isDistrictListLoading:false,
    districts:[],
    
    
    iscreateDistrictLoading:false,
    iscreateDistrictSuccess:false,
    iscreateDistrictError:false,
    createDistrictMessage:'',

    isAllLocationLoading:false,
    isAllLocationSuccess:false,
    isAllLocationError:false,
    AllLocations:[],

    iscreateAllLocationLoading:false,
    iscreateAllLocationSuccess:false,
    iscreateAllLocationError:false,
    createAllLocationMessage:'',

   
    isCreateForeignCountryLoading:false,
    isCreateForeignCountrySuccess:false,
    isCreateForeignCountryError:false,
    isCreateForeignCountryMessage:'',

    isGetAllForeignCountryLoading:false,
    isGetAllForeignCountrySuccess:false,
    isGetAllForeignCountryError:false,
    isGetAllForeignCountryMessage:'',
    ForeignCountries:[],
   
    isGetCountryByIDLoading:false,
    isGetCountryByIDSuccess:false,
    isGetCountryByIDError:false,
    isGetCountryByIDMessage:'',
    ForeignCountryDetail:{},

    isDeleteCountryLoading:false,
    isDeleteCountrySuccess:false,
    isDeleteCountryError:false,
    isDeleteCountryMessage:'',

    isUpdateCountryLoading:false,
    isUpdateCountrySuccess:false,
    isUpdateCountryError:false,
    isUpdateCountryMessage:''

}

export const getAllStars = createAsyncThunk(
    'master/getAllStars',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllStars()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllRasis = createAsyncThunk(
    'master/getAllRasis',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllRasis()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllReligions = createAsyncThunk(
    'master/getAllReligions',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllReligions();
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllQualifications= createAsyncThunk(
    'master/getAllQualifications',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllQualifications()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)


export const getQualificationById=createAsyncThunk(
   'master/getQualificationById',
   async(id,thunkAPI) =>{
    try{
        return await masterService.getQualificationById(id)
    }
    catch(error){
        const message = (error.response && error.response.data &&
            error.response.data.message) || error.message || error.tostring()

            return thunkAPI.rejectWithValue(message)
    }
   }
)

export const createQualification=createAsyncThunk(
    'master/createQualification',
    async(data,thunkAPI)=>{
        try{
            return await masterService.createQualification(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()
    
                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getjobById= createAsyncThunk(
    'master/getjobById',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getjobById()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllJobs= createAsyncThunk(
    'master/getAllJobs',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllJobs()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)
export const createjob=createAsyncThunk(
    'master/createjob',
    async(data,thunkAPI)=>{
        try{
            return await masterService.createjob(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()
    
                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAlllocation=createAsyncThunk(
    'master/getAlllocation',
    async(thunkAPI)=>{
        try{
            return await masterService.getAlllocation()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()
    
                return thunkAPI.rejectWithValue(message)
        }
    }
)
export const getAllDistricts = createAsyncThunk(
    'brok/getAllDistricts',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllDistricts()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const createdistricts=createAsyncThunk(
    'master/createdistricts',
    async(data,thunkAPI)=>{
        try{
            return await masterService.createdistricts(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()
    
                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const createotherlocation = createAsyncThunk(
    '/master/craeteotherlocation',
    async(data,thunkAPI)=>{
        try{
            return await masterService.createotherlocation(data)
        }
        catch(error){
            const message=(error.response && error.response.data && 
                error.response.data.message) || error.message || error.tostring()
                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllForeignCountries=createAsyncThunk(
    'master/getAllForeignCountries',
    async(thunkAPI)=>{
        try{
            return await masterService.getAllForeignCountries()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()
    
                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const createForeignCountry=createAsyncThunk(
    'master/createForeignCountry',
    async(data,thunkAPI)=>{
        try{
            return await masterService.createForeignCountry(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()
    
                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getForeignCountryById =createAsyncThunk(
    'master/getForeignCountryById',
    async(id,thunkAPI)=>{
        try{
            return await masterService.getForeignCountryById(id)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()
                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const deleteCountry=createAsyncThunk(
    'master/deleteCountry',
    async(data,thunkAPI)=>{
        try{
            return await masterService.deleteCountry(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()
    
                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const updateCountry=createAsyncThunk(
    'master/updateCountry',
    async(data,thunkAPI)=>{
        try{
            return await masterService.updateCountry(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()
    
                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const masterSlice = createSlice({
    name:'master',
    initialState,
    reducers:{
        resetMaster:(state)=>{
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ''
            state.masterDataList = [{"stars":[],"states":[]}]
        },
        resetcreateDistrict:(state)=>{
            state.iscreateDistrictLoading = false
            state.iscreateDistrictSuccess = false
            state.iscreateDistrictError = false
            state.createDistrictMessage=''
        },
        resetDistrictsByState:(state)=>{
            state.isDistrictListLoading=false
            state.isDistrictListSuccess=false
            state.isDistrictListError=false
        },
        resetgetalllocation:(state)=>{
            state.isAllLocationLoading=false
            state.isAllLocationSuccess=false
            state.isAllLocationError=false
            // state.AllLocations=[]
        },
        resetUpdateQualification:(state)=>{
            state.isUpdateQualificationLoading=false
            state.isUpdateQualificationSuccess=false
            state.isQualificationByIdError=false
            state.isUpdateQualificationMessage=''
        },
        resetDeleteQualification:(state)=>{
           state.isDeleteQualificationSuccess=false
           state.isDeleteQualificationLoading=false
           state.isDeleteQualificationError=false
           state.isDeleteQualificationMessage=''
        },
        resetCreateCountry:(state)=>{
            state.isCreateForeignCountryLoading=false
            state.isCreateForeignCountrySuccess=false
            state.isCreateForeignCountryError=false
            state.isCreateQualificationMessage=''
         },
         resetGetallCountry:(state)=>{
             state.isGetAllForeignCountryLoading=false
             state.isGetAllForeignCountrySuccess=false
             state.isGetAllForeignCountryError=false
             state.isGetAllForeignCountryMessage=''
         },
         resetCountryByID:(state)=>{
            state.isGetCountryByIDLoading=false
            state.isGetCountryByIDSuccess=false
            state.isGetCountryByIDError=false
            state.isDeleteCountryMessage=''
            state.ForeignCountryDetail={}
        },
        resetDeleteCountry:(state)=>{
            state.isDeleteCountryLoading=false
            state.isDeleteCountrySuccess=false
            state.isDeleteCountryError=false
            state.isDeleteCountryMessage=''
        },
        resetUpdateCountry:(state)=>{
            state.isUpdateCountryLoading=false
            state.isUpdateCountryLoading=false
            state.isUpdateCountryError=false
            state.isUpdateCountryMessage=''
        },
        resetcreateotherlocation:(state)=>{
            state.iscreateAllLocationLoading=false
            state.iscreateAllLocationSuccess=false
            state.isAllLocationError=false
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getAllStars.pending,(state)=>{
            state.isLoading = true;
        })
        .addCase(getAllStars.fulfilled,(state,action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.masterDataList.stars = action.payload
        })
        .addCase(getAllStars.rejected,(state,action)=>{
            state.isLoading = false
            state.isError = true
            state.masterDataList.stars = []
            
        })
        .addCase(getAllReligions.pending,(state)=>{
            state.isMasterReligionLoading = true;
        })
        .addCase(getAllReligions.fulfilled,(state,action)=>{
            state.isMasterReligionLoading = false
            state.isMasterReligionSuccess = true
            state.masterDataReligionList = action.payload
        })
        .addCase(getAllReligions.rejected,(state,action)=>{
            state.isMasterReligionLoading = false
            state.isMasterReligionError = true
            state.masterDataReligionList = []
        })

        .addCase(createQualification.pending,(state)=>{
            state.isCreateQualificationLoading=true;
        })
        .addCase(createQualification.fulfilled,(state,action)=>{
           state.isCreateQualificationLoading=false
           state.isCreateQualificationSuccess=true;
           state.isCreateQualificationMessage=action.payload.message;
        })
        .addCase(createQualification.rejected,(state,action)=>{
           state.isCreateQualificationLoading=false
           state.isCreateQualificationError=true
           state.isCreateQualificationMessage=action.payload;
        })

        .addCase(getAllQualifications.pending,(state)=>{
            state.isQualificationLoading = true;
         })
        .addCase(getAllQualifications.fulfilled,(state,action)=>{
             state.isQualificationLoading = false
             state.isQualificationSuccess = true
             state.qualifications = action.payload
         })
        .addCase(getAllQualifications.rejected,(state,action)=>{
             state.isQualificationLoading = false
             state.isQualificationListError = true
             state.qualifications = []
         })

         .addCase(getQualificationById.pending,(state)=>{
            state.isQualificationByIdloading =true;
         })
         .addCase(getQualificationById.fulfilled,(state,action)=>{
            state.isQualificationByIdloading = false
            state.isQualificationByIdSuccess = true
            if(action.payload.isSuccess ==true){
                state.qualification =action.payload.qualification
            }
            else{
                state.isMastermessage =action.payload.message;
                state.qualification={}
            }
         })
         .addCase(getQualificationById.rejected,(state,action)=>{
            state.isQualificationByIdloading =false
            state.isQualificationByIdSuccess =false
            state.isQualificationByIdError =true
            state.isQualificationByIdMessage =action.payload
            state.qualification ={}
         })

         .addCase(getAllJobs.pending,(state)=>{
            state.isJobListLoading=true
         })
         .addCase(getAllJobs.fulfilled,(state,action)=>{
            state.isJobListLoading=false
            state.isJobListSuccess=true
            state.jobs=action.payload
         })
         .addCase(getAllJobs.rejected,(state,action)=>{
            state.isJobListLoading=false
            state.isJobListSuccess=false
            state.isJobListError=true
            state.jobs=[]
         })

         .addCase(getAlllocation.pending,(state)=>{
            state.isAllLocationLoading=true
         })
         .addCase(getAlllocation.fulfilled,(state,action)=>{
            state.isAllLocationLoading=false
            state.isAllLocationSuccess=true
            state.AllLocations=action.payload
         })
         .addCase(getAlllocation.rejected,(state,action)=>{
            state.isAllLocationLoading=false
            state.isAllLocationSuccess=false
            state.isAllLocationError=true
            state.AllLocations=[]
         })
         .addCase(createotherlocation.pending,(state)=>{
            state.iscreateAllLocationLoading=true
         })
         .addCase(createotherlocation.fulfilled,(state,action)=>{
            state.iscreateAllLocationLoading=false
            state.iscreateAllLocationSuccess=true
            state.createAllLocationMessage=action.payload.message
         })
         .addCase(createotherlocation.rejected,(state,action)=>{
            state.isAllLocationLoading=false
            state.isAllLocationError=true
            state.isCreateQualificationMessage=action.payload
         })

         .addCase(createdistricts.pending,(state)=>{
            state.iscreateDistrictLoading=true;
         })
         .addCase(createdistricts.fulfilled,(state,action)=>{
            state.iscreateDistrictLoading=false
            state.iscreateDistrictSuccess=true
            state.createDistrictMessage=action.payload.message
         })
         .addCase(createdistricts.rejected,(state,action)=>{
            state.iscreateDistrictLoading=false
            state.iscreateDistrictSuccess=false
            state.iscreateDistrictError=true
            state.createDistrictMessage=action.payload.message
         })
         .addCase(getAllDistricts.pending,(state)=>{
              state.isDistrictListLoading = true;
         })
         .addCase(getAllDistricts.fulfilled,(state,action)=>{
               state.isDistrictListLoading = false
               state.isDistrictListSuccess = true
               state.districts = action.payload
         })
         .addCase(getAllDistricts.rejected,(state,action)=>{
               state.isDistrictListLoading = false
               state.isDistrictListSuccess=false
               state.isDistrictListError = true
               state.districts = []
        })
        .addCase(getAllForeignCountries.pending,(state)=>{
            state.isGetAllForeignCountryLoading = true;
         })
        .addCase(getAllForeignCountries.fulfilled,(state,action)=>{
             state.isGetAllForeignCountryLoading = false
             state.isGetAllForeignCountrySuccess = true
             state.ForeignCountries = action.payload;
         })
        .addCase(getAllForeignCountries.rejected,(state)=>{
             state.isGetAllForeignCountryLoading = false
             state.isCreateForeignCountryError = true
             state.ForeignCountries = []
         })
    }    
   
})


export const {resetCreateCountry,resetCountryByID,resetCreateQualification,resetQualificationById,resetGetQualification,resetGetallCountry
    ,resetMaster,resetcreatejob,resetgetjobById,resetgetalljob,resetdeletejob,resetcreateotherlocation,resetcreateDistrict
} = masterSlice.actions

export default masterSlice.reducer