import './App.scss';
import './scss/common.scss';
import { Routes, Route } from 'react-router';
import { BrowserRouter, useLocation, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './App/Store';
import { ToastContainer } from 'react-toastify';

// Import components
import { Login } from './Pages/User/Login';
import { ResetUser } from './Pages/User/ResetUser';
import { Register } from './Pages/User/Register';
import { BrokerList } from './Pages/Broker/BrokerList';
import { ProfileList } from './Pages/Profile/ProfileList';
import { PublicProfile } from './Pages/Profile/PublicProfile';
import { RegisterProfile } from './Pages/Profile/RegisterProfile';
import { AddProfileImage } from './Pages/Profile/AddProfileImage';
import { EditProfile } from './Pages/Profile/EditProfile';
import { RegisterBroker } from './Pages/Broker/RegisterBroker';
import { ProfileHoroscope } from './Pages/Profile/ProfileHoroscope';
import { BrokerHome } from './Pages/Broker/BrokerHome';
import { Dashboard } from './Pages/Common/Dashboard';
import { TermsOfUse } from './Pages/Common/TermsOfUse';
import { NavBar } from './Pages/Common/NavBar';
import { Footer } from './Pages/Common/Footer';
import { Pdfpage } from './Pages/TestFiles/Pdfpage';
import { Pdftest1 } from './Pages/TestFiles/Pdftest1';
import { AddBrokerImage } from './Pages/Broker/AddBrokerImage';
import { ImageCrop } from './Pages/TestFiles/ImageCrop';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { AddUserByBroker } from './Pages/User/AddUserByBroker';
import { UsersByBrokerList } from './Pages/User/UsersByBrokerList.jsx';
import { EditUserByBroker } from './Pages/User/EditUserByBroker.jsx';
import { OfficerRegister } from './Pages/Test/OfficerRegister.jsx';
// import { WorkerRegister } from './Pages/Test/workerRegister.jsx';
import { EditOfficer } from './Pages/Test/OfficerEdit.jsx';
import {JobList} from './Pages/Configuration/JobList.jsx'
import {QualificationList} from './Pages/Configuration/QualificationList.jsx'
import {ForeignCountryList} from './Pages/Configuration/ForeignCountryList.jsx'
import {BrokerProfile} from './Pages/Profile/BrokerProfile.jsx'
import {AdminProfile} from './Pages/Profile/AdminProfile.jsx'
import {OtherLocationList} from './Pages/Configuration/OtherLocation.jsx'
import{DistrictList} from './Pages/Configuration/DistrictList.jsx'




// Conditional Navbar component
function ConditionalNavBar() {
  const location = useLocation();
  return location.pathname !== '/Login' && location.pathname !== '/' ? <NavBar /> : null;
}

function ConditionalFooter() {
  const location = useLocation();
  return location.pathname !== '/Login' && location.pathname !== '/' ? <Footer /> : null;
}


export default function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        {/* Conditional NavBar */}
        <ConditionalNavBar />

        {/* Main content container */}
        <div className="container" style={{ minHeight: '610px', padding: '15px' }}>
        {/* <GoogleReCaptchaProvider reCaptchaKey="6LfOt60qAAAAAC7D3mqw1FwDQVGDcsWUwTX8PXXy"> */}
          <Routes>
            {/* Define Routes with leading slashes */}
            <Route path="/Login" element={<Login />} />
            <Route path="/BrokerList" element={<BrokerList />} />
            <Route path="/Registeruser" element={<Register />} />
            <Route path="/ProfileList" element={<ProfileList />} />
            <Route path="/PublicProfile" element={<PublicProfile />} />
            <Route path="/RegisterProfile" element={<RegisterProfile />} />
            <Route path="/AddProfileImage" element={<AddProfileImage />} />
            <Route path="/RegisterBroker" element={<RegisterBroker />} />
            <Route path="/EditProfile" element={<EditProfile />} />
            <Route path="/ProfileHoroscope" element={<ProfileHoroscope />} />
            <Route path="/BrokerHome" element={<BrokerHome />} />
            <Route path="/AddBrokerImage" element={<AddBrokerImage />} />
            <Route path="/Pdfpage" element={<Pdfpage />} />
            <Route path="/Pdftest1" element={<Pdftest1 />} />
            <Route path="/ImageCrop" element={<ImageCrop />} />
            <Route path="/ResetUser" element={<ResetUser />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/TermsOfUse" element={<TermsOfUse />} />
            <Route path="/AddUserByBroker" element={<AddUserByBroker />} />
            <Route path="/UsersByBrokerList" element={<UsersByBrokerList />} />
            <Route path="/EditUserByBroker" element={<EditUserByBroker />} />
            
            <Route path="/OfficerRegister" element={<OfficerRegister/>}></Route>
            {/* <Route path="/WorkerRegister" element={<WorkerRegister/>}></Route> */}
            <Route path="/EditOfficer" element={<EditOfficer/>}></Route>
            {/* <Route path="/JobList" element={<JobList/>}></Route> */}
            <Route path="/QualificationList" element={<QualificationList/>}></Route>
            <Route path="/ForeignCountryList" element={<ForeignCountryList/>}/>
            <Route path="/BrokerProfile" element={<BrokerProfile/>}/>
            <Route path="/AdminProfile" element={<AdminProfile/>}/>
            <Route path="/OtherLocationList" element={<OtherLocationList/>}></Route>
            <Route path="/DistrictList" element={<DistrictList/>}></Route>


          
            {/* Redirect root ("/") to "/Login" */}
            <Route path="/" element={<Navigate to="/Login" />} />

            {/* Fallback route for unknown paths */}
            <Route path="/*" element={<Login />} />
          </Routes>
          {/* </GoogleReCaptchaProvider> */}
        </div>

        {/* Toast notifications */}
        <ToastContainer />

        {/* Conditional Footer */}
        <ConditionalFooter/>
      </Provider>
    </BrowserRouter>
  );
}
