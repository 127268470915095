import { configureStore } from "@reduxjs/toolkit";
import authReducer from '../Features/Slices/authSlice'
import brokReducer from '../Features/Slices/brokSlice'
import profReducer from '../Features/Slices/profSlice'
import dashSlice from '../Features/Slices/dashboardSlice'
import  masterSlice  from "../Features/Slices/masterSlice";

const store = configureStore({
    reducer:{
        auth:authReducer,
        brok:brokReducer,
        prof:profReducer,
        dash:dashSlice,
        master:masterSlice
    }
})

export default store
