import { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { getAllForeignCountries,createForeignCountry,resetGetallCountry,resetCreateCountry, resetCountryByID} from "../../Features/Slices/masterSlice";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom"

export function ForeignCountryList(){
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const [searchParams] = useSearchParams();
    const CountryId =searchParams.get('id');

    const[formData,setFormData]=useState({
        foreignCountry:''
    })
    
    const{foreignCountry}=formData
    const onchange=(e)=>{
      setFormData((prevState)=>({
       ...prevState,
       [e.target.name]:e.target.value
      })) 
    }
    
    
    const {ForeignCountries,isGetAllForeignCountryLoading,isGetAllForeignCountrySuccess,isGetAllForeignCountryMessage,
        isCreateForeignCountryLoading,isCreateForeignCountrySuccess,isCreateForeignCountryMessage
    }
    =useSelector(
        (state)=>state.master)

    useEffect(()=>{
        if(isGetAllForeignCountryLoading==false && isGetAllForeignCountrySuccess==false){
            dispatch(getAllForeignCountries())
        }

        if(isCreateForeignCountrySuccess){
            toast.success(isCreateForeignCountryMessage)
            dispatch(resetCreateCountry())
            dispatch(resetGetallCountry())
 
        }
    },[isGetAllForeignCountrySuccess,isGetAllForeignCountryLoading,isCreateForeignCountrySuccess,isCreateForeignCountryMessage],dispatch)

    const onsubmit=(e)=>{
        e.preventDefault(); 
    
            const submit={foreignCountry}
        console.log(submit)
        dispatch(createForeignCountry(submit))
        }
    const backViewDetailsUrl ='/ProfileList'
        const onBackClick=(e)=>{
            e.preventDefault();
            navigate(backViewDetailsUrl)}

            const [isDisplayMode, setIsVisible] = useState(true);
           const toggleVisibility = () => {
            setIsVisible(!isDisplayMode);
          }

    const [show,setShow]=useState(false);

    const [ModelPopupData,setModePopupData]=useState({
        title:"Add Foreign Country",
        subtitle:"Foreign Country"
    })
    const{title,subtitle}=ModelPopupData
    
    const handleClose =()=>setShow(false);

            const handleShow=(popuptype)=>{
              setShow(true)

              if(popuptype =="AddCountry"){
                setModePopupData({
                  title:"Add Foreign Country",
                  subtitle:"Foreign Country"
                })
              }
              else{
                setModePopupData({
                  title:"Delete Foreign Country",
                  subtitle:"Delete"
                })
              }
            }

            const updateCountry =(foreignCountry)=>{
                alert(foreignCountry.foreignCountry) 
                 setIsVisible(!isDisplayMode);
                 //write the save functionality
               }

              //  const [hasError, setHasError] = useState(false);
              //  const {deleteName}=formData
              //  const onDeleteClick = () => {

    
  //   if (deleteName != foreignCountry) {
  //   //   setHasError(true)
  //     handleShow()
  //   }
  //   else {
  //     handleClose()
  //     const data = {"CountryId": CountryId }
  //     dispatch((data))
  //   }
  // }

  //              const onDeleteNamechange = () => {
  //               if (deleteName != foreignCountry) {
  //               //   setHasError(true)
  //               }
  //               else {
  //               //   setHasError(false)
  //               }
  //             }


    return(<>
     <Link onClick={onBackClick} className="text-success text-decoration-none"><h5>Home</h5></Link>
    <nav className="navbar">
      <div className="container-fluid">
        <h4>Foreign Country</h4>
        <div className="d-flex"> <input type="text" className="form-control border-success" placeholder="Search" onChange={onchange}></input>
          <div className="me-2 "><button className="btn btn-success rounded-0"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
    </svg></button></div>
          <button className="btn btn-outline-success col-4" type="submit" onClick={()=>handleShow("AddCountry")}>Add more</button>
        </div>
      </div>
    </nav><br />

    <div className="container-fluid py-0">
    <div className="row row-cols-1 row-cols-lg-3 g-2 g-lg-3">
        {ForeignCountries.map((foreignCountry,index)=>(
            <div key={index} className="col">
                <div className="p-3 border border-dark rounded">
                    Foreign Country
                    <div className="float-end">
 { isDisplayMode &&
            <Link style={{marginLeft:'220px'}} onClick={()=>toggleVisibility (foreignCountry._id)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="23"  className="bi bi-success" viewBox="0 0 16 16">
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
              <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
              </svg>
        </Link>}
{isDisplayMode &&
<Link style={{marginLeft:'10px'}} onClick={()=>handleShow("DeleteCountry")}>
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24"  className="bi bi-trash bi-info" viewBox="0 0 16 16">
         <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
         <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
         </svg>
        </Link>}
</div>
<div className="flot-start">
{isDisplayMode && <b>{foreignCountry.foreignCountry} </b>}</div>
{!isDisplayMode && <input type="text"  value={foreignCountry.foreignCountry}
          name="qualification" id="qualification" 
          className="border border-success p-1"></input> 
           }
           <div className="float-end">
           {!isDisplayMode &&
         <button type="button" className="btn-close" aria-label="Close" onClick={toggleVisibility}></button>}
         {!isDisplayMode &&
<svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft:'15px'}}onClick={() => updateCountry(foreignCountry)} width="16" height="16" fill="currentColor" className="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
</svg>}</div>
                </div>
            </div>
        ))}
    </div>
    </div>

    <Modal show={show} onHide={handleClose}>  
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <form onSubmit={onsubmit}>
        <Modal.Body>
          <div className="form-group">
            <p>{subtitle}</p>
            <input className="form-control" name="foreignCountry" id="foreignCountry" type="text" placeholder="Enter foreignCountry" onChange={onchange}/>
          </div>
        </Modal.Body>
        
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="success" onClick={handleClose}>
            Submit
          </Button>
        </Modal.Footer>
        </form>
      </Modal>

    </>)
}

